// 

import { Date } from "core-js";
import { dataTool } from "echarts";

export const crumb = (menus, path) => {
  let pathArr = []
  const p = menus.filter(item => {
    return item.index === path;
  })
  if (p) {
    pathArr.push({
      name: p[0].title,
      path: p[0].index
    })
  }
  return pathArr
}
//long型数据转化 将得到的数据做时间转换
export const timesort = (arr = [], prop) => {
  arr.forEach(item => {
    if (item[prop]) {
      const t = item[prop];
      let date = new Date();
      date.setTime(t);
      const m = date.getMonth(date) < 10 ? '0' + (date.getMonth(date) + 1) : (date.getMonth(date) + 1);
      const d = date.getDate(date) < 10 ? '0' + date.getDate(date) : date.getDate(date)
      const h = date.getHours(date) < 10 ? '0' + date.getHours(date) : date.getHours(date)
      const minutes = date.getMinutes(date) < 10 ? '0' + date.getMinutes(date) : date.getMinutes(date)
      const s = date.getSeconds(date) < 10 ? '0' + date.getSeconds(date) : date.getSeconds(date)
      item[prop] = date.getFullYear() + '-' + m + '-' + d + ' ' + h + ':' + minutes + ':' + s;
    }
  });
  return arr;
}
// long型时间传参 获取当前时间做转换
export const timeset = () => {
  const time = new Date();
  time.setTime(time.getTime());
  const tn = time.getFullYear() + "-" + (time.getMonth() + 1) + "-" + time.getDate() + " " + time.getHours() + ":" + time.getMinutes() + ':' + time.getSeconds();
  var nt = new Date(tn.replace(new RegExp("-", "gm"), "/")).getTime()
  return nt
}
export const timsete = (t) => {
  let date = new Date(t);
  // date.setTime(t);
  const m = date.getMonth(date) < 10 ? '0' + (date.getMonth(date) + 1) : (date.getMonth(date) + 1);
  const d = date.getDate(date) < 10 ? '0' + date.getDate(date) : date.getDate(date)
  const h = date.getHours(date) < 10 ? '0' + date.getHours(date) : date.getHours(date)
  const minutes = date.getMinutes(date) < 10 ? '0' + date.getMinutes(date) : date.getMinutes(date)
  const s = date.getSeconds(date) < 10 ? '0' + date.getSeconds(date) : date.getSeconds(date)
  const time = date.getFullYear() + '-' + m + '-' + d + ' ' + h + ':' + minutes + ':' + s;
  return time;
}
// 标准时间转换成long型传参
export const timeChange = (v) => {
  const t = timsete(v)
  const currentDateLong = new Date(t.replace(new RegExp("-", "gm"), "/")).getTime()
  return currentDateLong;
}
export default { crumb, timesort, timeset, timeChange }

