import http from "@/axios/index"
import { URI } from "@/config/axios"

export const getClient = (d = {}) => {
  return http({
    url: URI.system + 'api/get-client',
    method: 'get',
    data: d
  })
}
export const getManager = (d = {}) => {
  return http({
    url: URI.system + 'api/get-manager',
    method: 'get',
    data: d
  })
}
export const getTab2 = (d = {}) => {
  return http({
    url: URI.system + 'api/getTab2',
    method: 'get',
    data: d
  })
}

export const getTypeDesc = (d = {}) => {
  return http({
    url: URI.system + 'api/getType-desc',
    method: 'get',
    data: d
  })
}
export const getAudit = (d = {}) => {
  return http({
    url: URI.system + 'api/getAudit',
    method: 'get',
    data: d
  })
}
// 资产管理
export const getSource = (d = {}) => {
  return http({
    url: URI.system + 'api/tenant/assets',
    method: 'get',
    params: {
      ...d
    }
  })
}
// 资产管理 - 行业分类信息查询
export const getIndustry = (d = {}) => {
  return http({
    url: URI.system + 'api/tenant/industries',
    method: 'get',
    params: { ...d }
  })
}
//资产管理 - 资产新增
export const saveAsset = (d = {}) => {
  return http({
    url: URI.system + 'api/asset',
    method: 'post',
    data: {
      ...d
    }
  })
}
// 资产管理 删除资产
export const delAsset = (d = {}) => {
  return http({
    url: URI.system + 'api/asset/' + d,
    method: 'delete',
    params: { ...d }
  })
}
// 资产管理 属性查询

export const getAttribute = (d = {}) => {
  return http({
    url: URI.system + 'api/plugins/telemetry/' + d.entityType + '/' + d.entityId + '/values/attributes/' + d.scope,
    method: 'get',
    // params:{...d}
  })
}
// 资产管理 属性保存
export const addAttribute = (d = {}, b = {}) => {
  return http({
    url: URI.system + 'api/plugins/telemetry/' + d.entityType + '/' + d.entityId + '/' + d.scope,
    method: 'post',
    data: { ...b }
  })
}
// 资产管理 属性删除
export const delAttribute = (d = {}, b = {}) => {
  return http({
    url: URI.system + 'api/plugins/telemetry/' + d.entityType + '/' + d.entityId + '/' + d.scope,
    method: 'DELETE',
    params: { ...b }
  })
}
// 资产管理 警告
export const alarm = (d = {}, b = {}) => {
  return http({
    url: URI.system + 'api/alarm/' + d.entityType + '/' + d.entityId,
    method: 'get',
    params: { ...b }
  })
}
// 资产管理  关联查询
export const relation = (d = {}) => {
  return http({
    url: URI.system + 'api/relations/info',
    method: 'get',
    params: { ...d }
  })
}
// 资产管理 关联删除
export const delRelation = (d = {}) => {
  return http({
    url: URI.system + 'api/relation',
    method: 'DELETE',
    params: { ...d }
  })
}
// 资产管理 新建关联
export const serRelation = (d = {}) => {
  return http({
    url: URI.system + 'api/relation',
    method: 'post',
    data: { ...d }
  })
}
// 资产管理  事件
export const events = (d = {}, b = {}) => {
  return http({
    url: URI.system + 'api/events/' + d.entityType + '/' + d.entityId + '/' + d.type,
    method: 'get',
    params: { ...b }
  })
}
// 资产管理  审计日志
export const audit = (d = {}, b = {}) => {
  return http({
    url: URI.system + 'api/audit/logs/entity/' + d.entityType + '/' + d.entityId,
    method: 'get',
    params: { ...b }
  })
}
// 资产管理 关联页 类型信息查询
export const assetType = (d = '', b = {}) => {
  return http({
    url: URI.system + 'api/tenant/' + d,
    method: 'get',
    params: { ...b }
  })
}
// 关联客户查询

export const assetcustom = (b = {}) => {
  return http({
    url: URI.system + 'api/customers',
    method: 'get',
    params: { ...b }
  })
}
