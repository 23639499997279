import http from "@/axios/index";
import { URI } from "@/config/axios";

// GET /api/device/types getDeviceTypes
// export const getDeviceTypes = (data) => {
//     return http({
//         url: URI.system + 'api/device/types' ,
//         method: 'get',
//         data
//     })
// }
// GET /api/customer/{customerId}/devices{?type,textSearch,idOffset,textOffset,limit}
export const getCustomerDevices = (data) => {
  return http({
    url: URI.system + "api/customer",
    method: "get",
    data,
  });
};
// GET /api/tenant/devices{?type,textSearch,idOffset,textOffset,limit}
export const getTenantDevices = (data) => {
  return http({
    url: URI.system + "api/tenant/devices",
    method: "get",
    params: data,
  });
};
// 设备管理 新增
//
export const saveDevice = (d = {}) => {
  const b = localStorage.getItem("token");
  return http({
    url: URI.system + "api/device",
    method: "post",
    data: { ...d },
  });
};
// 设备管理 删除
export const delDevice = (d = {}) => {
  return http({
    url: URI.system + "api/device/" + d,
    method: "delete",
    params: { ...d },
  });
};
// 设备管理 设备类型查询
export const getDeviceType = () => {
  return http({
    url: URI.system + "api/tenant/findByTenantId",
    method: "get",
  });
};
// 设备管理 警告
export const clientAlarm = (d = {}, b = {}) => {
  return http({
    url: URI.system + "api/alarm/" + d.entityType + "/" + d.entityId,
    method: "get",
    params: { ...b },
  });
};
// 设备管理 事件
export const clientEvents = (d = {}, b = {}) => {
  return http({
    url:
      URI.system +
      "api/events/" +
      d.entityType +
      "/" +
      d.entityId +
      "/" +
      d.type,
    method: "get",
    params: { ...b },
  });
};
// 设备管理 关联
export const relation = (d = {}) => {
  return http({
    url: URI.system + "api/relations/info",
    method: "get",
    params: { ...d },
  });
};
// 设备管理 审计日志
export const clientAudit = (d = {}, b = {}) => {
  return http({
    url:
      URI.system + "api/audit/logs/entity/" + d.entityType + "/" + d.entityId,
    method: "get",
    params: { ...b },
  });
};
// 设备管理 认证
export const saveDeviceCredentials = (d = {}, b = {}) => {
  return http({
    url: URI.system + "api/device/credentials",
    method: "post",
    data: { ...d },
  });
};
// 设备管理 认证 获取 credentialsId
export const getDeviceCredentialsByDeviceId = (d = {}) => {
  return http({
    url: URI.system + "/api/device/" + d.id + "/credentials",
    method: "get",
    // params: { ...d }
  });
};
// 设备管理 关联 编辑查询
export const assetRelation = (d = {}, b = {}) => {
  return http({
    url: URI.system + "api/" + d.type + "/" + d.id,
    method: "get",
    // data: { ...d }
  });
};
// 总数查询
export const Count = (d) => {
  return http({
    url: URI.system + "api/tenant/count/",
    method: "get",
    params: { deviceType: d },
  });
};
